import MainLogo from '../../Images/ItqanHubPrimaryLogo1.png';
import { Link as RouterLink ,useLocation} from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect ,useState} from 'react';
import { Helmet } from 'react-helmet-async';
import './HomePage.css';
const HomePage = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Company: '',
    MobileNumber: '',
    Message: ''
  });
  useEffect(() => {
    if (location.state?.scrollToContactUs) {
      const element = document.getElementById('ContactUs');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const ContactUsHandler = async (event)=>{
      event.preventDefault();
      if(!event.target.Name.value || !event.target.Email.value || !event.target.Company.value || !event.target.MobileNumber.value ||!event.target.Message.value){
        toast.warn("Please Fill Required Fields");
      }
      else{
      try {
        const response = await fetch('https://itqanhub.com/Apis/ContactUs.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
    
        if (!response.ok) {
          const errorText = await response.text(); 
          throw new Error(`HTTP error! status: ${response.status}, ${errorText}`);
        }
        await response.json(); 
        const formsPreeResponse = await fetch('https://formspree.io/meqygzln', {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: {
              'Accept': 'application/json',
          },
      });
      if (!formsPreeResponse.ok) {
          const errorText = await formsPreeResponse.text();
          throw new Error(`Formspree error! status: ${formsPreeResponse.status}, ${errorText}`);
      }
        await formsPreeResponse.json();
        toast.success("Thanks for reaching out! We've received your message");
        setFormData({
          Name: '',
          Email: '',
          Company: '',
          MobileNumber: '',
          Message: ''
        });
          } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        toast.warn("We Couldn't Receive Your Message Please Try Again Later");
              }
            }
      ReactGA.event({
        category: 'Form',
        action: 'Submission',
        label: 'Contact Form'
      });      
    };
    const handleLearnMoreClick = ()=>{
      ReactGA.event({
        category: 'Button',
        action: 'Click', 
        label: 'Learn More'
      });
    }
    const handleServiceClick = (service)=>{
      if(service===1){
        ReactGA.event({
          category: 'Button',
          action: 'Click', 
          label: 'IT Training Services Clicked From Home'
        });
      }
      else if(service===2){
        ReactGA.event({
          category: 'Button',
          action: 'Click', 
          label: 'Professional Services Clicked From Home'
        });
      }
    }
      return (
    <>
    <Helmet>
        <meta name="description" content="Welcome to Itqan Hub, your premier destination for IT training and professional services. Join us on a transformative adventure to unleash untapped potential." />
    </Helmet>
    <div className="LogoContainer">
       <ToastContainer />
        <img src={MainLogo} className="MainLogo" alt="Main Logo" loading="lazy"/>
    </div>
    <h2 className="Slogan"><span>Empowering Minds</span> <br/> Transforming Futures </h2>
    <section className="HomePageDescriptionSection" >
    <div className="DesktopsDescriptionContainer">
    <h4 className="HomePageDescription">Welcome to <span>ITQAN HUB</span>, your premier destination for IT training, educational upskilling, professional services and consultations. Based in Egypt, we are proud to serve as a beacon of excellence in the heart of the Middle East and Africa region.</h4>
    </div> 
     <div className="MobileDescriptionContainer">
    <h4 className="HomePageDescription">Welcome to <span>ITQAN HUB</span>, your premier destination for IT training, educational upskilling, professional services and consultations. Based in Egypt, we are proud to serve as a beacon of excellence in the heart of the Middle East and Africa region.</h4>
     </div> 
</section>
<RouterLink to="/AboutUs" className="LearnMoreButton Button" onClick={handleLearnMoreClick}>Learn More</RouterLink>
  <hr/>
  <h2 className="Title">Our Services</h2>
  <section >
    <div className="HomeServicesPreview">
<div className="card">
  <RouterLink to="/OurServices" className="card1" href="Services.html" onClick={()=>handleServiceClick(1)}>
   <p>IT Training Services</p>
   <hr className='ServicePreviewLine'/>
   <p className="small">Offering diverse IT training services, covering product training, knowledge transfer, vendor accreditations, and sales trainings for channel empowerment.
    </p>
   <div className="go-corner" >
     <div className="go-arrow">
      <i className="fa-solid fa-circle-info"></i>
     </div>
   </div>
 </RouterLink>
</div>
<div className="card"  >
  <RouterLink to="/OurServices" className="card1" href="Services.html" onClick={()=>handleServiceClick(2)}>
   <p>Professional Services</p>
   <hr className='ServicePreviewLine'/>
   <p className="small">Our professional services encompass proof of concept (POC) and labs, consultancy, solution implementation and enablement, as well as support services</p>
   <div className="go-corner">
     <div className="go-arrow">
      <i className="fa-solid fa-circle-info"></i>
     </div>
   </div>
 </RouterLink>
</div>
</div>
<hr />
<section id="ContactUs">
<h2 className="Title">Contact Us</h2>
<h4 className="SubTitle">Drop Us A Line!</h4>
<form  className="ContactForm" id="ContactUsForm" onSubmit={ContactUsHandler}>
<label htmlFor="Name" >Name <span id="NameAsterik">*</span><br/>
    <input type="Text" name="Name" id="Name" value={formData.Name} placeholder="Your Name Goes Here..."  onChange={handleInputChange} autoComplete="Name"/>
</label>
<label  htmlFor="MobileNumber">Mobile Number <span id="MobileNumberAsterik">*</span><br/>
        <input type="text" name="MobileNumber" id="MobileNumber"  value={formData.MobileNumber} onChange={handleInputChange} placeholder="Your Mobile Number Goes Here..."/>
    </label>
<label  htmlFor="email">Email <span id="EmailAsterik">*</span><br/>
<input type="email" name="Email" id="email"placeholder="Your Email Goes Here..." value={formData.Email} onChange={handleInputChange} autoComplete="email"/>
</label>
<label  htmlFor="Company">Company <span id="CompanyAsterik">*</span><br/>
    <input type="text" name="Company" id="Company" placeholder="Your Company Goes Here..."value={formData.Company} onChange={handleInputChange} autoComplete="Company"/>
</label>
<label htmlFor="ContactMessage">Message <span>*</span><br/>
<textarea name="Message" id="ContactMessage" placeholder="Your text Goes Here..." value={formData.Message} onChange={handleInputChange}></textarea>  
</label>
<button type="submit" className="Button" id="SubmitButton">Send</button>
</form>
</section>
<hr />
</section>
<div className="LocationSection" >
    <h2 className="Title">Our Office</h2>
   <h5 className="LocationDescription">Makateb Offices,Building 74,Unit 206,Banks Center St,New Cairo1,<br/>Cairo Governorate,Egypt
</h5>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55258.20168720633!2d31.330877333802082!3d30.047252977488892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583d671b3693ad%3A0x46ed9c9c45eb2cb2!2sItqan%20Hub!5e0!3m2!1sen!2seg!4v1708026087864!5m2!1sen!2seg" style={{ border: 0 }} title="Map"/>
</div>
    </>
  )
}

export default HomePage
