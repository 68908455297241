import { useState ,useEffect} from 'react';
import CourseCard from '../CourseCard/CourseCard';
import Placeholder from '../Placeholder/Placeholder';
import SchedulePlaceholder from '../../Images/SchedulePlaceholder.svg';
import { Helmet } from 'react-helmet-async';
import './SchedulePage.css';
const SchedulePage = () => {
const [courses,setCourses] = useState([]);
const [error, setError] = useState(null);
useEffect(() => {
  fetch('https://itqanhub.com/Apis/Courses.php')
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((fetchedData) => {
      setCourses(fetchedData);
      setError(null); 
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setError(error.message);
    });
}, []);
let coursesCards;
if (courses && courses.length > 0 && !error) {
  coursesCards = courses.map((course) => (
    <CourseCard key={course.Id} course={course} />
  ));
} else {
  coursesCards = (
    <Placeholder img={SchedulePlaceholder} text="We Will Add Our Schedule Soon. Stay tuned!" />
  );
}
  return (
    <div className="SchedulePage">
    <Helmet>
    <meta name="description" content="Stay updated with our upcoming training sessions and events! Explore our schedule and register now." />
<meta name="keywords" content="training sessions, upcoming events, event schedule, register for training, IT training schedule, professional development events, training calendar, event registration, educational events, training programs" />
    </Helmet>
      <h2 className="Title">Schedule</h2>
      <section className="CourseList">
        <div className="CalendarContainer CourseList">
           {coursesCards}
        </div>
    </section>
 
    </div>
  )
}


export default SchedulePage
