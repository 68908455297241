import {Link as RouterLink} from 'react-router-dom';
import ReactGA from 'react-ga4';
import './CourseCard.css';
const CourseCard = ({course}) => {
  const courseImg = require(`../../Images/${course.PngName}.png`);
  const coursePdf = require (`../../PDFs/${course.PDFName}.pdf`);
  const startDateTime = new Date(course.StartDate);
  const endDateTime = new Date(course.EndDate);
  const startDay = startDateTime.getDate();
  const endDay = endDateTime.getDate();
  const startMonth = startDateTime.toLocaleString('default', { month: 'short' });
  const endMonth = endDateTime.toLocaleString('default', { month: 'short' });
  const parseTime = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return { hours, minutes };
  };
  const startTime = parseTime(course.StartTime);
  const endTime = parseTime(course.EndTime);

  let startHour = startTime.hours;
  let startMinutes = startTime.minutes;
  let endHour = endTime.hours;
  let endMinutes = endTime.minutes;
  const sPmorAm = startHour >= 12 ? 'PM' : 'AM';
  const ePmorAm = endHour >= 12 ? 'PM' : 'AM';

  startHour = startHour % 12 || 12;
  endHour = endHour % 12 || 12;
  const enrollButtonClicked = ()=>{
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Training Enroll Button' 
    });
  }
  return (
    <div className="card2 card1">
    <div className="CalendarLogos">
      <img
        src={courseImg}
        alt={`${course.PngName} Logo`}
      />
    </div>
    <h3 className="CourseTitle">{course.CourseName}</h3>
    <hr className="ServicesPreviewLine CardLine" />
    <div className="CourseDetails">
      <div className="CalendarDate">
        <div className="CalendarHeader">
          <h4 className="CalendarMonth">
            {startMonth === endMonth
              ? startMonth.toUpperCase()
              : `${startMonth.toUpperCase()}-${endMonth.toUpperCase()}`}
          </h4>
        </div>
        <div className="CalendarBody">
          <h3 className="DateText">
            {startDateTime.toDateString() === endDateTime.toDateString()
              ? startDay
              : `${startDay} - ${endDay}`}
          </h3>
        </div>
      </div>
      <div className="TimeandPlace">
        <h4 className="Time">
          {startHour}:{startMinutes.toString().padStart(2, '0')} {sPmorAm} - {endHour}:{endMinutes.toString().padStart(2, '0')} {ePmorAm}
        </h4>
        <h4 className="Time">{course.Place}</h4>
      </div>
    </div>
    <a href={coursePdf} className="DownloadPDF" target="_blank" rel="noopener noreferrer">
      Training Description
    </a>
    <RouterLink to={`/Training/${course.CourseName}/${startDay}/${startMonth}`} onClick={enrollButtonClicked}>
      <button className="Button CalendarButton">Enroll</button>
    </RouterLink>
  </div>
  )
}

export default CourseCard
