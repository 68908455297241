import { Helmet } from 'react-helmet-async';
import './AboutUsPage.css';
const AboutUsPage = () => {
  return (
    
    <section className="AboutUsSection">
      <Helmet>
        <meta name="description" content="At Itqan Hub, our mission is to bridge the skills gap and empower individuals and organizations across the Middle East and Africa to thrive in the rapidly evolving tech landscape." />
        <meta name="keywords" content="about us, ItqanHub, team, mission" />
    </Helmet>
    <h2 className="Title">About ITQAN HUB</h2>
    <div className="DesktopAboutUs">
    <h3 id="About1stParagraph" className="AboutParagraph">
        Welcome to <span>ITQAN HUB</span>, your premier destination for IT training, educational upskilling, professional services and consultations. Based in Egypt, we are proud to serve as a beacon of excellence in the heart of the Middle East and Africa region.</h3>
   <h3  className="AboutParagraph" >At <span>ITQAN HUB</span>, our mission is to bridge the skills gap and empower individuals and organizations across the Middle East and Africa to thrive in the rapidly evolving tech landscape. We are committed to providing high-quality training and consultancy services tailored to the unique needs and challenges of our diverse clientele.
</h3>
<h3 id="About3rdParagraph" className="AboutParagraph">With a comprehensive range of training formats including instructor-led sessions, virtual instructor-led trainings, blended and hybrid formats, and self-paced e-learning modules, we ensure accessibility and flexibility for learners from all backgrounds and locations.</h3>
    <h3  className="AboutParagraph">Our team of industry experts and certified professionals brings a wealth of knowledge and practical insights to every training session and consultation, enabling our clients to acquire relevant skills and insights that directly translate into real-world success.
    </h3>
    <h3 id="About4thParagraph" className="AboutParagraph">Join us at <span>ITQAN HUB</span> as we embark on a transformative journey to unlock new opportunities and drive positive change across the Middle East and Africa. Together, let's shape the future of technology and education in our region.
    </h3>
</div>
<div className="MobileAboutUs">
    <h5 id="MobileAbout1stParagraph" className="MobileAboutParagraph">  Welcome to <span>ITQAN HUB</span>, your premier destination for IT training, educational upskilling,professional services and consultations.</h5>
    <h5  className="MobileAboutParagraph">Based in Egypt, we are proud to serve as a beacon of excellence in the heart of the Middle East and Africa region.</h5>
    <h5  className="MobileAboutParagraph">At <span>ITQAN HUB</span>, our mission is to bridge the skills gap and empower individuals and organizations across the Middle East and Africa to thrive in the rapidly evolving tech landscape.</h5>
    <h5  className="MobileAboutParagraph">We are committed to providing high-quality training and consultancy services tailored to the unique needs and challenges of our diverse clientele.</h5>
    <h5  className="MobileAboutParagraph">With a comprehensive range of training formats including instructor-led sessions, virtual instructor-led trainings, blended and hybrid formats, and self-paced e-learning modules, we ensure accessibility and flexibility for learners from all backgrounds and locations.</h5>
    <h5  className="MobileAboutParagraph">Our team of industry experts and certified professionals brings a wealth of knowledge and practical insights to every training session and consultation, enabling our clients to acquire relevant skills and insights that directly translate into real-world success.</h5>
    <h5  id="MobileAbout7thParagraph" className="MobileAboutParagraph">Join us at <span>ITQAN HUB</span> as we embark on a transformative journey to unlock new opportunities and drive positive change across the Middle East and Africa. Together, let's shape the future of technology and education in our region.</h5>
</div>
    </section>
    
  )
}

export default AboutUsPage
