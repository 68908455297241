import NavLogo from '../../Images/ItqanHubEmblem1.png';
import { useState , useEffect} from 'react';
import { Link as RouterLink,useLocation,useNavigate} from 'react-router-dom';
import { Link as ScrollLink} from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga4';
import './Header.css'
const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const MobileNavBarHandler = ()=>{
      setIsMenuOpen(!isMenuOpen);
    }
    const ContactUsNavigation = ()=>{
      navigate('/', { state: { scrollToContactUs: true } });
    }
    useEffect(() => {
      setIsMenuOpen(false);
      if (!ReactGA.ga) {
        //Testing
        ReactGA.initialize('G-T40Z98FS64');
    }
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [location]);
  return (
    <>
        <div className="NavBardiv">
        <img className="NavLogo"src={NavLogo} alt="Navigation Bar Logo"/>
    <div className="NavBarContainer">
    <div className="NavBar">
        <RouterLink to="/" className={location.pathname === '/' ? 'active-link LinkStyle' : 'LinkStyle'}> Home </RouterLink>
        <RouterLink to="/AboutUs" className={location.pathname === '/AboutUs' ? 'active-link LinkStyle' : 'LinkStyle'}> About Us </RouterLink>
        <RouterLink to="/OurServices" className={location.pathname === '/OurServices' ? 'active-link LinkStyle' : 'LinkStyle'}> Our Services </RouterLink>
        <RouterLink to="/OurPartners" className={location.pathname === '/OurPartners' ? 'active-link LinkStyle' : 'LinkStyle'}> Our Partners </RouterLink>
        <RouterLink to="/Schedule" className={location.pathname === '/Schedule' ? 'active-link LinkStyle' : 'LinkStyle'}> Schedule </RouterLink>
        <ScrollLink to="ContactUs" smooth={true} duration={500} className={location.pathname === '/ContactUs'? 'active-link LinkStyle' : 'LinkStyle'} onClick={ContactUsNavigation}>Contact Us</ScrollLink>
        <RouterLink to="/Jobs" className={location.pathname === '/Jobs' || location.pathname === '/JobDetails' ? 'active-link LinkStyle' : 'LinkStyle'}>Jobs</RouterLink>
    </div>
</div>
</div>
<div className="MobileNavBarContainer">
        <div className="MobileNavBar">
          <RouterLink to="/">
        <img className="NavLogo" src={NavLogo} alt="Navigation Bar Logo"/>
          </RouterLink>
        <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} color='white'style={{ color: 'Black', fontSize: '26px'}} onClick={MobileNavBarHandler}/>
    </div>
    {isMenuOpen && <div id="MobileNavBarActive" className="MobileNavBarActive" >
        <RouterLink to="/" className={location.pathname === '/' ? 'ActiveNavBar LinkStyle' : 'LinkStyle'}> Home </RouterLink>
        <RouterLink to="/AboutUs" className={location.pathname === '/AboutUs' ? 'ActiveNavBar LinkStyle' : 'LinkStyle'}> About Us </RouterLink>
        <RouterLink to="/OurServices" className={location.pathname === '/OurServices' ? 'ActiveNavBar LinkStyle' : 'LinkStyle'}> Our Services </RouterLink>
        <RouterLink to="/OurPartners" className={location.pathname === '/OurPartners' ? 'ActiveNavBar LinkStyle' : 'LinkStyle'}> Our Partners </RouterLink>
        <RouterLink to="/Schedule" className={location.pathname === '/Schedule' ? 'ActiveNavBar LinkStyle' : 'LinkStyle'}> Schedule </RouterLink>
        <ScrollLink to="ContactUs" smooth={true} duration={500} className={location.pathname === '/ContactUs' ? 'ActiveNavBar LinkStyle' : 'LinkStyle'} onClick={ContactUsNavigation}>Contact Us</ScrollLink>
        <RouterLink to="/Jobs" className={location.pathname === '/Jobs'  || location.pathname === '/JobDetails' ? 'ActiveNavBar LinkStyle' : 'LinkStyle'}>Jobs</RouterLink>
     
    </div> }
    </div>
</>
  )
}

export default Header
