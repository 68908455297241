import FortinetLogo from '../../Images/FortinetLogo.png';
import FortinetLogo2 from '../../Images/Fortinet.png';
import FortinetCertification from '../../Images/certfications.jpg';
import { Helmet } from 'react-helmet-async';
import './OurPartnersPage.css';
const OurPartnersPage = () => {
  return (
    <>
     <Helmet>
        <meta name="description" content="Discover our trusted partners in delivering top-tier IT training and certification programs." />
        <meta name="keywords" content="IT training partners, certification programs, IT training providers, professional certifications, IT education, training partnerships, top IT training, certification partners, IT training programs, technology education partners" />
    </Helmet>
      <section className="PartnersSection">
    <h2 className="Title">Our Partners</h2>
    <div  className="PartnersDesktop">
    <div>

        <h3 className="DesktopMainParagraph">At <span>ITQAN HUB</span>, We prioritize partnering with organizations upholding the highest standards of quality and reliability. These partnerships guarantee the development of premium products, fostering a mutually beneficial environment that collectively expands our capabilities and market reach.
            
            Our diverse network of partners contributes valuable expertise and creative solutions, enabling us to tackle complex challenges with agility and innovation.
            
        </h3>
    </div>
    <hr/> 
        <div className="ImgDiv">
           
            <img src={FortinetLogo} alt="Fortinet Logo" className="FortinetImg"/>
        </div>
        <h3 className="DesktopMainParagraph" id="FortinetParagraph" >Fortinet is a global leader in cybersecurity solutions, well known for its cutting-edge technology and comprehensive security platforms. Their products and services protect enterprises, service providers, and government organizations against constantly evolving cyber threats.
        </h3>
    <div className="PartnersCard">
        <div className="ImgContainer">
           
            <img src={FortinetLogo2} alt="Fortinet Logo" className="PartnersImg"/>
        </div>
        <h3 className="DesktopMainParagraph" ><span>ITQAN HUB </span>has been accredited as an authorized training center by FORTINET, spanning Egypt, Libya, and Sudan. This partnership is a testament to our unwavering commitment to providing our customers with exceptional services and offerings, marking a new era of growth and innovation. In addition, it facilitates FORTINET’s expansion into these three significantly large markets.
        </h3>

    </div> 
  
    <div className="SecondContainer">
        <h2 className="Title">The Fortinet NSE certification</h2>
        <h3 className="DesktopMainParagraph"><span>ITQAN HUB</span> is proud to be the authorized training center for Fortinet, offering their Network Security Expert (NSE) Certification Program. This program is carefully crafted and extensively researched to address the evolving threat landscape and the demands of the job market. It spans a comprehensive range of cybersecurity knowledge, from foundational to expert levels, ensuring that participants are well-prepared for current and future challenges</h3>
            <h3 className="DesktopMainParagraph">By providing the NSE Certification Program, we are committed to helping professionals achieve excellence in network security and maintain a competitive edge in the industry.
            </h3>
            <h3 className="DesktopMainParagraph">The certification program at <span>ITQAN HUB</span> includes a variety of courses across different tracks. These courses are available in multiple formats, allowing participants to choose the option that best fits their learning style.
            </h3>
            <h3 className="DesktopMainParagraph">Additionally, <span>ITQAN HUB</span> features hands-on exercises in well-equipped labs to demonstrate complex security concepts. With this comprehensive training, <span>ITQAN HUB</span> ensures that candidates gain a deep understanding and expertise in their chosen field.</h3>
</div>
<div className="CertficationDiv">
    <img className="Cerficationsimg"  src={FortinetCertification} alt="Five Proficiency Levels with 12 Certifications"/>
</div>
<h5 className="imgSubtitle" >Five Proficiency Levels with 12 Certifications</h5>
</div>
<div className="PartnersMobile">
    <h5 className="MobileMainParagraph">At <span>ITQAN HUB</span>,We prioritize partnering with organizations upholding the highest standards of quality and reliability.</h5>
    <h5 className="MobileMainParagraph">These partnerships guarantee the development of premium products, fostering a mutually beneficial environment that collectively expands our capabilities and market reach.</h5>
    <h5 className="MobileMainParagraph"> Our diverse network of partners contributes valuable expertise and creative solutions, enabling us to tackle complex challenges with agility and innovation.</h5>
    <hr/>
    <div className="MobilesPartnersCard">
        <div className="ImgDiv">
           
            <img src={FortinetLogo} alt="Fortinet Logo" className="FortinetImg"/>
        </div>
        <h5 className="MobileMainParagraph" >Fortinet is a global leader in cybersecurity solutions, well known for its cutting-edge technology and comprehensive security platforms. Their products and services protect enterprises, service providers, and government organizations against constantly evolving cyber threats.
        </h5>
           
        <img src={FortinetLogo2} alt="Fortinet Logo" className="MobilePartnersImg"/>
        <h5 className="MobileMainParagraph" ><span>ITQAN HUB </span>has been accredited as an authorized training center by FORTINET, spanning Egypt, Libya, and Sudan. This partnership is a testament to our unwavering commitment to providing our customers with exceptional services and offerings, marking a new era of growth and innovation. In addition, it facilitates FORTINET’s expansion into these three significantly large markets.
            </h5>
        </div>
    
        <div className="SecondContainer">
            <h2 className="Title">The Fortinet NSE certification</h2>
            <h5 className="MobileMainParagraph"><span>ITQAN HUB</span> is proud to be the authorized training center for Fortinet, offering their Network Security Expert (NSE) Certification Program. </h5>
            <h5 className="MobileMainParagraph">This program is carefully crafted and extensively researched to address the evolving threat landscape and the demands of the job market.</h5>
            <h5 className="MobileMainParagraph"> It spans a comprehensive range of cybersecurity knowledge, from foundational to expert levels, ensuring that participants are well-prepared for current and future challenges.</h5>
            <h5 className="MobileMainParagraph">By providing the NSE Certification Program, we are committed to helping professionals achieve excellence in network security and maintain a competitive edge in the industry.</h5>
            <h5 className="MobileMainParagraph">The certification program at <span>ITQAN HUB </span>includes a variety of courses across different tracks. These courses are available in multiple formats, allowing participants to choose the option that best fits their learning style.</h5>
            <h5 className="MobileMainParagraph">Additionally, <span>ITQAN HUB</span> features hands-on exercises in well-equipped labs to demonstrate complex security concepts. With this comprehensive training, <span>ITQAN HUB</span> ensures that candidates gain a deep understanding and expertise in their chosen field.</h5>
            <div className="CertficationDiv">
                <img className="Cerficationsimg"  src={FortinetCertification} alt="Five Proficiency Levels with 12 Certifications"/>
            </div>
            <h5 className="imgSubtitle" >Five Proficiency Levels with 12 Certifications</h5>
            </div>
            </div>
</section>
    </>
    
  )
}

export default OurPartnersPage
